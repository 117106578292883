import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import ImageWebp from 'src/components/ImageWebp'

import { EscolhaSuaAssinaturaContainer, CardAssinatura, CardWrapper } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
interface IProps {
  openModal: () => void;
  oneLink: string;
}

const WIDTH_MD = 720

const EscolhaSuaAssinatura = ({ openModal, oneLink }: IProps) => {
  const windowWidth = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  const arrayNumbers: number[] = [
    324, 300, 400, 453,
  ]

  const arrayHeight: number[] = [
    324, 300, 400, 453,
  ]

  return (
    <EscolhaSuaAssinaturaContainer id='escolha-sua-assinatura' className='py-5 d-flex align-items-center justify-content-center position-relative'>
      <div className='container'>
        <div className='row align-items-center justify-content-center justify-content-lg-between'>
          <div className='col-12 col-md-5 col-lg-5 px-0 px-xl-2 order-lg-last pt-md-4 pt-lg-5 mb-4 text-center text-md-right'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-assinatura-duo/image.webp'
              altDescription='Mão segurando um celular mostrando o app do Duo Gourmet e um cartão black na outra mão com uma refeição sobre a mesa ao fundo.'
              arrayNumbers={arrayNumbers}
              arrayNumbersHeight={arrayHeight}
            />
          </div>
          <div className='col-12 col-md-9 col-lg-6'>
            <div>
              <h2 className='w-100 fs-24 lh-30 fs-md-40 lh-md-50 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 text-md-center text-lg-left mr-lg-n5'>
                <span className='d-md-none'>Assine agora e garanta</span>
                <span className='d-none d-md-block col-md-8 col-lg-12 mx-auto px-lg-0 fs-lg-32 fs-xl-40 lh-xl-50'>Assine agora e aproveite</span>
              </h2>
            </div>
            <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 text-grayscale--400 pt-2 mb-lg-4 pb-lg-2'>
              2 pratos pelo preço 1, em milhares de restaurantes do Brasil, sem limite de uso! No plano anual, você ainda garante o upgrade para o cartão Inter Black.
            </p>
            <CardWrapper>
              <CardAssinatura
                black
                className='d-flex flex-column align-items-center text-center'
                onClick={() => {
                  openModal()
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    section_name: 'Assine agora e aproveite/Escolha sua assinatura',
                    element_action: 'click button',
                    element_name: 'Assinatura Anual',
                  })
                }}
              >
                <span className='fs-16 lh-20 fw-600 mb-2'>Assinatura Anual</span>
                <span className='value fs-32 lh-40 fw-600 mb-2'>12x R$45</span>
                <span className='fs-16 lh-20 fw-400 mb-0'>Ganhe um Cartão Black</span>
              </CardAssinatura>
              <CardAssinatura
                className='d-flex flex-column align-items-center text-center'
                onClick={() => {
                  openModal()
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    section_name: 'Assine agora e aproveite/Escolha sua assinatura',
                    element_action: 'click button',
                    element_name: 'Assinatura mensal',
                  })
                }}
              >
                <span className='fs-16 lh-20 fw-600 text-grayscale--500 mb-2'>Assinatura mensal</span>
                <span className='value fs-32 lh-40 fw-600 text-orange--base mb-2'>R$ 70,00</span>
                <span className='fs-16 lh-20 fw-400 text-grayscale--500 mb-0'>por mês</span>
              </CardAssinatura>
            </CardWrapper>
            {windowWidth > WIDTH_MD ? (
              <div className='d-flex align-items-center justify-content-center'>
                <button
                  className='btn btn-orange--extra fs-14 fw-700 text-none mt-4 mt-md-0'
                  title='Aproveitar agora'
                  onClick={() => {
                    openModal()
                    sendDatalayerEvent({
                      section: 'dobra_04',
                      section_name: 'Assine agora e aproveite/Escolha sua assinatura',
                      element_action: 'click button',
                      element_name: 'Aproveitar agora',
                    })
                  }}
                >
                  Aproveitar agora
                </button>
              </div>
           ) : (
             <div className='w-100 d-flex align-items-center justify-content-center'>
               <a
                 href={oneLink}
                 target='_blank'
                 rel='noreferrer'
                 title='Aproveitar agora'
                 className='btn btn-orange--extra fs-14 fw-700 text-none mt-4 mt-md-0'
                 onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    section_name: 'Assine agora e aproveite/Escolha sua assinatura',
                    element_action: 'click button',
                    element_name: 'Aproveitar agora',
                    redirect_url: oneLink,
                  })
                 }}
               >
                 Aproveitar agora
               </a>
             </div>
           )}
          </div>
        </div>
      </div>
    </EscolhaSuaAssinaturaContainer>
  )
}

export default EscolhaSuaAssinatura
