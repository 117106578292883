import React from 'react'
import { ClientContainer, PerfilData, ClientInfo, Review } from './style'
export interface IClientReviewProps {
  imageSource: string;
  clientName: string;
  reviewText: string;
}

const ClientReview = ({ imageSource, clientName, reviewText }: IClientReviewProps) => {
  return (
    <ClientContainer>
      <PerfilData>
        <img src={require(`../../assets/images/${imageSource}`)} alt={clientName} />
        <ClientInfo>
          <h3 className='fs-18 text-grayscale--400 fw-600 mb-0'>{clientName}</h3>
          <span className='text-grayscale--300 fs-16 lh-19 fs-md-18 lh-md-22 mt-2'>Cliente Duo Gourmet</span>
        </ClientInfo>
      </PerfilData>
      <Review className='fs-16 lh-19 fs-md-18 lh-md-22'>"{reviewText}"</Review>
    </ClientContainer>
  )
}

export default ClientReview
