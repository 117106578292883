import React from 'react'
import Img from 'gatsby-image'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import OrangeIcon from 'src/components/UI/MarkdownIcon/InterCoIcons'
import { orange } from 'src/styles/colors'

import usePageQuery from '../../pageQuery'

import { SoliciteSection } from './style'

const listData = [
  {
    icon: 'inter-loop',
    text: 'Ganhe mais pontos no <u>Inter Loop</u>: a cada R$2,50 em compras no crédito, você ganha 1 ponto (não expira!)',
    hasLink: 'Inter Loop',
  },
  {
    icon: 'i-safe',
    text: 'Proteção contra roubo em caixas eletrônicos',
    hasLink: '',
  },
  {
    icon: 'doctor',
    text: 'Seguro Bagagem e Seguro Médico em viagens',
    hasLink: '',
  },
  {
    icon: 'card-protection',
    text: 'Garantia estendida original e proteção de compra',
    hasLink: '',
  },
]

interface IItemData {
  icon: string;
  text: string;
  hasLink: string;
}

interface ISoliciteSeuCartao {
  openModal: () => void;
  oneLink: string;
}

const SoliciteSeuCartão = ({ openModal, oneLink }: ISoliciteSeuCartao) => {
  const data = usePageQuery()
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SoliciteSection className='py-5 position-relative d-md-flex justify-content-center align-items-md-center'>
      <div className='container'>
        <div className='row justify-content-between align-items-center'>
          <div className='col-12 col-md-7 col-lg-6'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fw-600 text-white pb-3 pr-md-5'>
              Duo Gourmet + cartão Black
            </h2>

            <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 text-white pb-4 mb-0 pt-3 pr-md-5'>
              Com a <strong>assinatura do Duo Gourmet</strong>, você pode solicitar o <strong>cartão Black Inter</strong>, que oferece um mundo de benefícios
              com a exclusividade que você merece.
            </p>

            {listData.map((item: IItemData, index: number) => (
              <div key={index} className='d-flex align-items-center mb-3'>
                <div>
                  <OrangeIcon icon={item.icon} size='MD' color={orange.extra} />
                </div>
                {item.hasLink ? (
                  <a
                    href='https://inter.co/pra-voce/cartoes/programa-de-pontos/'
                    target='blank'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_05',
                        section_name: 'Duo Gourmet + cartão Inter Black',
                        element_action: 'click button',
                        element_name: item.hasLink,
                        redirect_url: 'https://inter.co/pra-voce/cartoes/programa-de-pontos/',
                      })
                    }}
                  >
                    <p
                      className='fs-14 lh-17 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 fw-600 pl-3 text-white mb-0 pb-0'
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    />
                  </a>
                ) : (
                  <p
                    className='fs-14 lh-17 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 fw-600 pl-3 text-white mb-0 pb-0'
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                )}
              </div>
            ))}

            <a
              href='https://marketplace.bancointer.com.br/foods/blackcardterm.pdf'
              target='_blank'
              rel='noreferrer'
              title='Confira o regulamento'
              className='fs-14 lh-17 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 fw-600 d-block mt-4'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_05',
                  section_name: 'Duo Gourmet + cartão Inter Black',
                  element_action: 'click button',
                  element_name: 'Confira o regulamento',
                  redirect_url: 'https://marketplace.bancointer.com.br/foods/blackcardterm.pdf',
                })
              }}
            >
              Confira o regulamento
            </a>

            {width > WIDTH_MD ? (
              <button
                className='btn text-white btn-orange--extra text-none mt-2'
                title='Quero o cartão Black'
                onClick={() => {
                  openModal()
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    section_name: 'Duo Gourmet + cartão Inter Black',
                    element_action: 'click button',
                    element_name: 'Quero o cartão Black',
                  })
                }}
              >
                Quero o cartão Black
              </button>
            ) : (
              <a
                href={oneLink}
                target='_blank'
                rel='noreferrer'
                title='Quero o cartão Black'
                className='btn text-white btn-orange--extra text-none mt-2'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    section_name: 'Duo Gourmet + cartão Inter Black',
                    element_action: 'click button',
                    element_name: 'Quero o cartão Black',
                    redirect_url: oneLink,
                  })
                }}
              >
                Quero o cartão Black
              </a>
            )}
          </div>
          <div className='col-12 col-lg-6 col-xl-4 mt-5 d-md-none d-lg-block d-xl-none'>
            <Img fluid={data.cartaoInterBlackDuoGourmet.fluid} alt='Cartão Black Inter com símbolo mastercard' />
          </div>
        </div>
      </div>
    </SoliciteSection>
  )
}

export default SoliciteSeuCartão
