import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const DuoLoverrSection = styled.section`
background-color: ${grayscale['100']};
  h2 {
    font-family: "Sora";
  }

  button {
    height: 48px;
    padding-top: 7px;

    @media ${device.tablet} {
      min-width: 336px;
    }

    @media ${device.desktopLG} {
      min-width: 390px;
    }
  }
`
