import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const CircularNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: ${orange.extra};
  color: white;
  font-size: 22px;
  border-radius: 22px;
`

export const BoxCarousel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-left: 30px;

    @media ${device.desktopLG} {
      margin-left: 40px;    
    }
  }

  h3 {
    min-height: 70px;
  }
`
