import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'

export const HeroContainer = styled.section`
  background: ${grayscale[500]};
  min-height: 376px;

  @media ${device.tablet} {
    background: ${grayscale[500]} url('https://central-imagens.bancointer.com.br/images-without-small-versions/bg-md-duo/image.webp') no-repeat;
    background-position: center center;
    background-size: cover;
    align-items: center;
    min-height: 441px;
    padding-bottom: 40px;
  }

  @media ${device.desktopLG} {
    background: ${grayscale[500]} url('https://central-imagens.bancointer.com.br/images-without-small-versions/bg-lg-duo/image.webp') no-repeat;
    min-height: 521px;
    background-position: center center;
    background-size: cover;
  }

  @media ${device.desktopXL} {
    background: ${grayscale[500]} url('https://central-imagens.bancointer.com.br/images-without-small-versions/bg-xl-duo/image.webp') no-repeat;
    min-height: calc(100vh - 73px);
    background-position: center center;
    background-size: cover;
  }

  @media ${device.desktopXL} {
    min-height: 851px;
  }

  .btn {
    height: 48px;

    @media ${device.tablet} {
      max-width: 328px;
    }
    @media ${device.desktopLG} {
      max-width: 456px;
    }
  }
`
