import styled, { css } from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'

export const EscolhaSuaAssinaturaContainer = styled.section`
  background: ${white};

  h2 {
    font-size: 24px;
    line-height: 30px;

    @media ${device.tablet} {
      font-size: 40px;
      line-height: 50px;
    }
    @media ${device.desktopLG} {
      font-size: 32px;
      line-height: 40px;
    }
  }

  button, a {
    height: 48px;
    padding-top: 7px;

    @media ${device.tablet} {
      max-width: 456px;
    }

    @media ${device.desktopXXXL} {
      max-width: 100%;
    }
  }
`

export const CardAssinatura = styled.div<{black?: boolean}>`
  background: ${white};
  padding: 28px 0;
  max-width: 456px;
  width: 100%;
  border: 1px solid ${grayscale[100]};
  box-shadow: 0 0 40px rgba(60, 112, 190, 0.08);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  @media ${device.desktopXXXL} {
    max-width: 100%;
  }

  ${(props: {black?: boolean}) => props.black && css`
    background: ${grayscale[500]};
    color: ${white};
  `}
`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media ${device.tablet} {
    flex-direction: row;
    margin-bottom: 32px;
  }
`
