import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { WIDTH_MD } from 'src/styles/breakpoints'
import { HeroContainer } from './style'
interface IProps {
  openModal: () => void;
  oneLink: string;
}

const Hero = ({ openModal, oneLink }: IProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <HeroContainer
      className='justify-content-md-center d-md-flex bg-black'
      role='img'
      aria-label='Mão esquerda segurando um celular com o app do Duo Gourmet na tela e mão direita mostrando um cartão Black com um prato servido à mesa ao fundo.'
    >
      <div className='container py-5'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-lg-6 col-xl-5 pb-md-0 offset-md-6 offset-xl-7'>
            <h1 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 mb-4 mb-md-2 mb-lg-4 fw-600 text-white'>
              Assine o plano anual do Duo Gourmet e ganhe um Cartão Black
            </h1>
            <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-xl-18 lh-xl-22 mb-5 mb-md-4 mb-lg-5 text-white'>
              Com o Duo Gourmet <strong>você pede 2 pratos e paga apenas 1</strong>. Assine e comece a economizar nos <strong>melhores restaurantes</strong> do Brasil.
            </p>
            {width > WIDTH_MD ? (
              <button
                className='btn text-white btn-orange--extra text-none'
                title='Assinar agora'
                onClick={() => {
                  openModal()
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Assine o plano anual do Inter Duo Gourmet e ganhe um Cartão Black',
                    element_action: 'click button',
                    element_name: 'Assinar agora',
                   })
                }}
              >
                Assinar agora
              </button>
            ) : (
              <a
                href={oneLink}
                title='Assinar agora'
                className='btn text-white btn-orange--extra text-none'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Assine o plano anual do Inter Duo Gourmet e ganhe um Cartão Black',
                    element_action: 'click button',
                    element_name: 'Assinar agora',
                    redirect_url: oneLink,
                   })
                }}
              >
                Assinar agora
              </a>
            )}
          </div>
        </div>
      </div>
    </HeroContainer>
  )
}

export default Hero
