import { grayscale, white } from 'src/styles/colors'
import styled from 'styled-components'

export const ClientContainer = styled.div`
  background: ${white};
  box-shadow: 0 12.1319px 36.3956px rgba(75, 78, 92, 0.1);
  border-radius: 16px;
  padding: 24px 16px;

  img {
    width: 57px;
    height: 57px;
    border-radius: 28px;
  }
`

export const PerfilData = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

export const ClientInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 12px;
`

export const Review = styled.div`
  color: ${grayscale[500]}
`
