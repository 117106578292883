import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      fivestars: imageSharp(fluid: { originalName: { regex: "/fivestars/" } }) {
        fluid(maxWidth: 120, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      cartaoInterBlackDuoGourmet: imageSharp(fluid: { originalName: { regex: "/bg-duo-inter-black/" } }) {
        fluid(maxWidth: 452, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      nordesteDuo: imageSharp(fluid: {originalName: {regex: "/nordeste-duo-gourmet/"}}) {
        fluid(maxWidth: 80, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      sudesteDuo: imageSharp(fluid: {originalName: {regex: "/sudeste-duo-gourmet/"}}) {
        fluid(maxWidth: 80, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      norteDuo: imageSharp(fluid: {originalName: {regex: "/norte-duo-gourmet/"}}) {
        fluid(maxWidth: 80, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      centroOesteDuo: imageSharp(fluid: {originalName: {regex: "/centro-oeste-duo-gourmet/"}}) {
        fluid(maxWidth: 80, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      sulDuo: imageSharp(fluid: {originalName: {regex: "/sul-duo-gourmet/"}}) {
        fluid(maxWidth: 80, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
