import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import ImageWebp from 'src/components/ImageWebp'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import { CircularNumber, BoxCarousel } from './style'

const CarouselData = [
  {
    title: 'Escolha um <br /> restaurante',
    description: 'Para facilitar, use os <br /> filtros de busca',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/passo1-duo/image.webp',
    alt: 'tela do app Duo Gourmet mostrando a lista de restaurantes perto de mim que aceitam Duo Gourmet',
  },
  {
    title: 'Verifique as <br /> regras de uso',
    description: 'Consulte os dias e horários <br /> disponíveis',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/passo2-duo/image.webp',
    alt: 'tela do app Duo Gourmet mostrando a disponibilidade do restaurante e regras de uso.',
  },
  {
    title: 'Use o seu <br /> benefício',
    description: 'Ao fechar a conta, apresente o código gerado ao garçom',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/passo3-duo/image.webp',
    alt: 'tela do app Duo Gourmet mostrando o benefício validado para mostrar ao garçom no restaurante.',
  },
]

type ItemTypes = {
  title: string;
  description: string;
  image: string;
  alt: string;
}

const WIDTH_MD = 768

const ComoUsar = () => {
  const width = useWidth(300)
  const isMobile = WIDTH_MD > width

  const arrayNumbers: number[] = [
    210, 200, 280, 315,
  ]

  const arrayHeight: number[] = [
    395, 376, 527, 549,
  ]

  return (
    <section className='bg-grayscale--100'>
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12 mb-md-3'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 text-grayscale--500 text-center'>
              Como usar o Duo Gourmet
            </h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--400 text-center mb-0 mb-md-4'>
              É muito fácil comer bem e economizar. Confira!
            </p>
          </div>
        </div>
        {
          isMobile ? (
            <div className='row justify-content-center align-items-center'>
              <div className='col-12 col-xl-8'>
                <DefaultCarousel
                  sm={{ items: 1 }}
                  md={{ items: 3 }}
                  lg={{ items: 3 }}
                  xl={{ items: 3 }}
                >
                  {CarouselData.map((item: ItemTypes, index: number) => (
                    <BoxCarousel key={item.title}>
                      <div className='w-100 row d-flex align-items-center justify-content-center'>
                        <div className='col-9 col-md-12 d-flex flex-column align-items-center justify-content-center'>
                          <CircularNumber>{index + 1}</CircularNumber>
                          <h3 className='h-100 fs-20 lh-25 text-grayscale--500 text-center pt-4' dangerouslySetInnerHTML={{ __html: item.title }} />
                          <p className='fs-14 lh-17 fs-lg-15 lh-lg-19 text-grayscale-400 text-center pb-3' dangerouslySetInnerHTML={{ __html: item.description }} />
                          <div className='col px-0 mx-auto d-flex align-items-center justify-content-center'>
                            <ImageWebp
                              pathSrc={item.image}
                              altDescription={item.alt}
                              arrayNumbers={arrayNumbers}
                              arrayNumbersHeight={arrayHeight}
                            />
                          </div>
                        </div>
                      </div>
                    </BoxCarousel>
                    ),
                  )}
                </DefaultCarousel>
              </div>
            </div>
          ) : (
            <div className='row justify-content-between align-items-center'>
              {
                CarouselData.map((item: ItemTypes, index: number) => (
                  <div className='col-md-4 px-md-2' key={item.title}>
                    <BoxCarousel>
                      <div className='w-100 row d-flex align-items-center justify-content-center'>
                        <div className='col-8 col-md-12 d-flex flex-column align-items-center justify-content-center'>
                          <CircularNumber>{index + 1}</CircularNumber>
                          <h3 className='h-100 fs-20 lh-25 text-grayscale--500 text-center pt-4' dangerouslySetInnerHTML={{ __html: item.title }} />
                          <p className='fs-14 lh-17 fs-lg-15 lh-lg-19 text-grayscale-400 text-center pb-4 pb-md-3' dangerouslySetInnerHTML={{ __html: item.description }} />
                          <div className='col px-md-0 mx-auto d-md-flex align-items-md-center justify-content-md-center'>
                            <ImageWebp
                              pathSrc={item.image}
                              altDescription={item.alt}
                              arrayNumbers={arrayNumbers}
                              arrayNumbersHeight={arrayHeight}
                            />
                          </div>
                        </div>
                      </div>
                    </BoxCarousel>
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
    </section>
  )
}
export default ComoUsar
