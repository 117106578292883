import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const VantagensSection = styled.section`
  background: ${grayscale[100]};

  button, a {
    height: 48px;
    padding-top: 7px;

    @media ${device.tablet} {
      min-width: 336px;
    }
  }
`

export const CardStyle = styled.div`
  padding: 18px;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    font-family: 'Sora';
  }

  @media ${device.tablet} {
    min-height: 230px;
  }
  @media ${device.desktopLG} {
    padding: 24.55px;
    height: 301px;
  }
  @media ${device.desktopXL} {
    padding: 32px;
    height: 266px;
  }
`
