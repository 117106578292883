import { device } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'
import styled from 'styled-components'

export const SoliciteSection = styled.section`
  background-color: ${white};
  min-height: 690px;

  .cards-container {
      gap: 24px;
    }

  @media ${device.tablet} {
    min-height: 634px;
  }

  @media ${device.desktopLG} {
    min-height: 674px;
  }

  @media ${device.desktopXL} {
    min-height: 708px;
  }

  button, .btn {
    height: 48px;
    padding-top: 7px;
  }
`

export const CardContainer = styled.a`
  border: 1px solid #F5F6FA;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 2px 8px 0px rgba(22, 22, 22, 0.08);
  display: block;
  height: auto !important;

  :hover {
    background: #F5F6FA;
    border: 1px solid #DEDFE4;
    box-shadow: 0px 8px 16px 2px rgba(22, 22, 22, 0.08);
    transition: 500ms ease-in-out;
  }

  @media ${device.tablet} {
    width: 336px;
    margin-bottom: 0px;
  }

  @media ${device.desktopLG} {
    width: 296px;
  }

  @media ${device.desktopXL} {
    width: 360px;
  }
`
